import React from 'react';
import PropTypes from 'prop-types';
import Card from '../Card';
import ShortFacts from '../ShortFacts';
import { ThemeContext, themes } from '../../themes/ThemeContext';
import stylesNordr from './CardExternalProject.nordr.module.scss';
import stylesFolkhem from './CardExternalProject.folkhem.module.scss';

const getStyles = (theme) => ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]
);

class CardExternalProject extends React.PureComponent {
    render() {
        const { card, shortFacts, link } = this.props;
        const Tag = link ? 'a' : 'div';
        let attrs = {};
        if (link) {
            attrs.href = link.href;
        }

        const theme = this.context;
        const styles = getStyles(theme);

        const img =
            card.image && card.image.renditions
                ? card.image.renditions['standard900']
                : card.image;

        return (
            <Tag className={styles["CardExternalProject"]} {...attrs}>
                <div className={styles["CardExternalProject__Card"]}>
                    <Card {...card} image={img} modifier={"CardExternalProject"} />
                </div>
                <div className={styles["CardExternalProject__ShortFacts"]}>
                    <ShortFacts
                        {...shortFacts}
                        link={this.props.link}
                        modifier={"CardExternalProject"}
                    />
                </div>
            </Tag>
        );
    }
}

CardExternalProject.propTypes = {
    card: PropTypes.object,
    shortFacts: PropTypes.object,
    link: PropTypes.object,
};

CardExternalProject.defaultProps = {
    link: {
        href: '',
    },
    card: {
        image: {
            renditions: {},
        },
    },
};

CardExternalProject.contextType = ThemeContext;

export default CardExternalProject;
